import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Some fun with Svelte`}</h1>
    <p>{`Over the past year I've fiddled a lot with svelte and have made some simple but cool stuff.`}</p>
    <p>{`Something I love about Svelte is how familiar it is to writing JavaScript with some slightly new syntax. You can think of it like a framework except that instead of bundling the framework with your application and sending it to the user; Svelte is compiled at build time which allows for tiny bundles. It's also insanely fast rendering because it doesn't have the overhead of a virtual DOM like React (which generally doesn't matter but it's pretty cool regardless).`}</p>
    <h2>{`Classic Snake game`}</h2>
    <p>{`Wanting to stretch my svelte muscles but also do something I'm not super familiar with; I build the classic snake game using the HTML canvas and svelte. There is some places where I could make more DRY but in the spirit of keeping this focused on fun I'll leave it as-is. `}</p>
    <a target="_blank" href="https://svelte.dev/repl/60a675f4ac68495488855586f5208d01?version=3.44.3">Check out the classic snake game</a>
    <h2>{`Star Rating feature`}</h2>
    <p>{`This was a fun feature that I build using Svelte's built in animation library for a great user experience.`}</p>
    <a target="_blank" href="https://svelte.dev/repl/b6a9c67f21944c1c8ad2378a07c3cd48?version=3.32.3">Check out the demo for the start rating feature</a>
    <h2>{`Shy Top Bar`}</h2>
    <p>{`I love a sticky top bar but sometimes they get in the way and while scrolling they should get shy.`}</p>
    <a target="_blank" href="https://svelte.dev/repl/c3bcc7826fd34e43a7d4fff96b9dd3f9?version=3.32.3">Check out the demo for the shy top bar</a>
    <h2>{`Timer`}</h2>
    <p>{`Simple timer for counting down something. Could use a sound notification or more visual indication`}</p>
    <a target="_blank" href="https://svelte.dev/repl/aa6c438ff6c04851b63328e3eb54466c?version=3.32.3">Check out the demo for the timer</a>
    <h2>{`Sliding Sidebar`}</h2>
    <p>{`This is a native-like sliding drawer for websites. This is great for PWA's where you don't need the gesture based navigation on mobile devices but most mobile OS's now use the slide gesture which renders this less useful but I still think it's cool.`}</p>
    <a target="_blank" href="https://svelte.dev/repl/9310fb207516441e848b61acea51ae98?version=3.32.3">Check out the demo for the sliding sidebar</a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      